.button {
    height: 48px;
    padding-inline: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    outline: none;
    border-radius: var(--border-radius);
    z-index: 0;
    background: none;
    overflow: hidden;
    border: 2px solid var(--c-primary);
    color: var(--c-primary);
    font-size: var(--fs-body);
    font-weight: normal;
    box-sizing: border-box;
}

.button:hover {
    color: black;
}

.button:hover:after {
    width: 100%;
}

.button:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition: all 0.3s ease;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: var(--c-primary);
}

.primary-button {
    border-color: var(--c-primary);
}

.normal-button {
    border-color: #d5d5d5;
}

.normal-button:after {
    background-color: #d5d5d5;
}

.normal-button:hover {
    color: var(--c-border);
}