body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: var(--dark-bg);
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --fc-body: #d5d5d5;
    --fc-heading: #333333;
    --fc-light: #f6f6f6;
    --fc-subtitle: #FFFFFF7F;

    --fs-body: 0.875rem;
    --fs-h2: 1.3125rem;
    --fs-h3: 1.125rem;

    --c-primary: #63c8ff;
    --c-dark: #252525;
    --c-border: rgba(255, 255, 255, 0.05);
    --c-gray: #bbb;
    --c-transparent-background: rgba(128, 128, 128, 0.1);

    --icon-and-text-gap: 18px;

    --border-radius: 10px;
    --blur-radius: 24px;

    --padding-card: 16px 24px;

    --dark-bg: linear-gradient(to right top, #072E3D 0%, #07090C 50%, #07090C 50%, #30122A 100%);
}