.site-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}

.site-logo > .icon {
    background-color: var(--c-primary);
    display: inline-block;
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    text-align: center;
    border-radius: 50%;
    margin-right: 8px;

    position: relative;
}

.site-logo > .icon > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
}

@media screen and (max-width: 866px) {
    .site-logo {
        font-size: 16px;
    }

    .site-logo > .icon {
        min-width: 36px;
        max-width: 36px;
        min-height: 36px;
        max-height: 36px;
    }
}