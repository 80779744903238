@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

.background {
    font-family: 'Exo', sans-serif;
    box-sizing: border-box;
    position: absolute;
    z-index: -5;
    min-width: 100%;
    min-height: 100%;
}

.background * {
    margin: 0;
    padding: 0;
}

.background > div {
    position: absolute;
    filter: blur(100px);
}

.background > div:nth-child(1) {
    left: 25%;
    bottom: 50%;
    transform: translate(-25%,50%);
    width: clamp(200px, 50vw, 400px);
    fill: rgba(0, 255, 208, 0.35);
}

.background > div:nth-child(2) {
    left: 15%;
    top: 15%;
    transform: translate(-25%,-50%);
    width: 300px;
    fill: rgba(0, 99, 247, 0.47);
}

.background > div:nth-child(3) {
    right: 15%;
    bottom: 25%;
    width: clamp(400px, 60vw, 600px);
    fill: rgba(165, 0, 247, 0.25);
}

/*.background .area {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*.background .circles {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    overflow: hidden;*/
/*}*/

/*.background .circles li {*/
/*    position: absolute;*/
/*    list-style: none;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    mix-blend-mode: color;*/
/*    background: rgba(118, 118, 118, 0.47);*/
/*    animation: animate 25s linear infinite;*/
/*}*/

/*.background .circles li:nth-child(1) {*/
/*    left: 25%;*/
/*    width: 80px;*/
/*    height: 80px;*/
/*    animation-delay: 0s;*/
/*    bottom: 10%;*/
/*}*/


/*.background .circles li:nth-child(2) {*/
/*    left: 10%;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    animation-delay: 2s;*/
/*    animation-duration: 12s;*/
/*    bottom: 15%;*/
/*}*/

/*.background .circles li:nth-child(3) {*/
/*    left: 70%;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    animation-delay: 4s;*/
/*    bottom: 25%;*/
/*}*/

/*.background .circles li:nth-child(4) {*/
/*    left: 40%;*/
/*    width: 60px;*/
/*    height: 60px;*/
/*    animation-delay: 0s;*/
/*    animation-duration: 18s;*/
/*    bottom: 65%;*/
/*}*/

/*.background .circles li:nth-child(5) {*/
/*    left: 65%;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    animation-delay: 0s;*/
/*    bottom: 50%;*/
/*}*/

/*.background .circles li:nth-child(6) {*/
/*    left: 75%;*/
/*    width: 110px;*/
/*    height: 110px;*/
/*    animation-delay: 3s;*/
/*    bottom: 60%;*/
/*}*/

/*.background .circles li:nth-child(7) {*/
/*    left: 35%;*/
/*    width: 150px;*/
/*    height: 150px;*/
/*    animation-delay: 7s;*/
/*    bottom: 35%;*/

/*}*/

/*.background .circles li:nth-child(8) {*/
/*    left: 50%;*/
/*    width: 25px;*/
/*    height: 25px;*/
/*    animation-delay: 15s;*/
/*    animation-duration: 45s;*/
/*    bottom: 85%;*/
/*}*/

/*.background .circles li:nth-child(9) {*/
/*    left: 20%;*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    animation-delay: 2s;*/
/*    animation-duration: 35s;*/
/*    bottom: 90%;*/
/*}*/

/*.background .circles li:nth-child(10) {*/
/*    left: 85%;*/
/*    width: 150px;*/
/*    height: 150px;*/
/*    animation-delay: 0s;*/
/*    animation-duration: 11s;*/
/*    bottom: 95%;*/
/*}*/


/*@keyframes animate {*/

/*    0% {*/
/*        transform: translateY(0) rotate(0deg);*/
/*        opacity: 0;*/
/*        border-radius: 0;*/
/*    }*/

/*    50% {*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        transform: translateY(-250px) rotate(500deg);*/
/*        opacity: 0;*/
/*        border-radius: 50%;*/
/*    }*/

/*}*/