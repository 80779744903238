.home{
    position: relative;
    display: flex;
    flex-direction: column;
}

.home > .content{
    max-width: 1320px;
    margin: 0 auto;
}

.sections {
    max-width: 1024px;
    margin-inline: auto;
}

@media screen and (max-width: 1024px) {
    .sections {
        padding-inline: 16px;
    }
}