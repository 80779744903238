.hero-section {
    margin: 5% 10%;
}

.hero-section .download-resume{
    text-decoration: none;
}

@media screen and (min-width: 1100px) and (max-width: 1280px) {
    .hero-section {
        margin: 5% 5%;
    }
}

.hero-section .content {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 48px;
    height: 100%;
    align-items: center;
}

@media screen and (max-width: 1100px) and (min-width: 800px) {
    .hero-section .content {
        flex-direction: column;
    }

    .hero-section {
        margin: 16px 10%;
    }
}

@media screen and (max-width: 800px) {
    .hero-section .content {
        flex-direction: column;
    }

    .hero-section {
        margin: 5% 5%;
    }
}

.info-and-action {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.actions {
    display: flex;
    margin-top: 27px;
}

.actions > * {
    margin-right: 12px;
}