.footer {
    background-color: var(--c-transparent-background);
    display: flex;
    align-items: center;
    border-top: 1px solid var(--c-border);
    min-height: 56px;
    max-width: 100%;
    overflow: hidden;
    padding-inline: 3rem;
    margin-top: 2rem;
    backdrop-filter: blur(--blur-radius);
}

.footer > .contact {
    display: flex;
    flex-direction: row;
    gap: 36px;
}

@media screen and (max-width: 480px) {
    .footer{
        padding-block: 2rem;
        margin-top: 1rem;
    }

    .footer > .contact {
        flex-direction: column;
        gap: 2rem;
    }
}