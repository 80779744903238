.contact-link {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    fill: var(--fc-light);
    color: var(--fc-light);
    text-decoration: none;
    font-size: 13px;
    line-height: 21px;
    transition-duration: 200ms;
    opacity: 0.6;
}

.contact-link:hover {
    opacity: 1;
}

.contact-link > .icon{
    display: table;
    height: 100%;
}

.contact-link > .icon > *{
    display: table-cell;
    vertical-align: middle;
    width: 24px;
    height: 24px;
}
